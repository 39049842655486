<template>
  <div>
    <b-modal id="add-comment" size="lg" centered hide-header hide-footer>
      <div
        class="d-flex justify-content-between align-content-center border-bottom"
      >
        <h4 class="text-primary mb-2 font-weight-bold">
          {{ $t("transportation.addComment") }}
        </h4>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form
          class="position-relative form-top p-4"
          @submit.prevent="handleSubmit(sendComment)"
        >
          <b-row>
            <b-col md="12">
              <label for="cancel-reason" class="d-block label">{{
                $t("transportation.comment")
              }}</label>
              <textarea
                id="cancel-reason"
                :placeholder="$t('transportation.comment')"
                v-model="comment"
                class="p-2 d-block w-100"
              ></textarea>
              <!--              <input-form-->
              <!--                  class="mb-3"-->
              <!--                  :validate="'required'"-->
              <!--                  name="التعليق"-->
              <!--                  label="التعليق"-->
              <!--                  placeholder="التعليق"-->
              <!--                  v-model="comment"-->
              <!--              />-->
            </b-col>
            <b-col md="12" class="my-3">
              <h5 class="mb-2">{{ $t("transportation.rate") }}</h5>
              <ul class="list-unstyled m-0 d-flex align-items-center gap-1 p-0">
                <li v-for="(star, i) in stars" :key="i">
                  <i
                    class="las la-star icon font-size-25"
                    :class="[selectStar >= i ? 'selected' : '']"
                    @click="
                      selectStar = i;
                      $emit('changeStar', i);
                    "
                  ></i>
                </li>
              </ul>
            </b-col>
          </b-row>
          <div
            class="d-flex justify-content-center align-items-center w-25 m-auto mt-5"
          >
            <b-button type="submit" variant="primary" class="px-2 py-2" block
              ><span class="font-size-16">{{
                $t("transportation.addComment")
              }}</span></b-button
            >
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-row class="profile-icons-svg">
      <b-col lg="6">
        <div class="iq-card">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">
                {{ $t("transportation.trips") }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.135"
                height="25.135"
                viewBox="0 0 30.135 30.135"
              >
                <path
                  id="doctor-bag"
                  d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z"
                  transform="translate(-3 -3)"
                  fill="#646464"
                />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ count.allTrips }}</p>
          </div>
        </div>
      </b-col>
      <b-col lg="6">
        <div class="iq-card">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">الرحلات المنتهية</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.135"
                height="25.135"
                viewBox="0 0 30.135 30.135"
              >
                <path
                  id="doctor-bag"
                  d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z"
                  transform="translate(-3 -3)"
                  fill="#646464"
                />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">
              {{ count.finishedTrips }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <user-pages-title>
      <template v-slot:header-icon>
        <svg
          id="delivery-truck_1_"
          data-name="delivery-truck (1)"
          xmlns="http://www.w3.org/2000/svg"
          width="30.466"
          height="20.496"
          viewBox="0 0 33.466 24.496"
        >
          <g id="Group_145" data-name="Group 145" transform="translate(0 0)">
            <g id="Group_144" data-name="Group 144">
              <path
                id="Path_133"
                data-name="Path 133"
                d="M0,234.777a.611.611,0,0,0,.584.665h.932a4.131,4.131,0,0,1,3.706-2.633,4.131,4.131,0,0,1,3.706,2.633h12.11v-5.377H0Z"
                transform="translate(0 -215.695)"
                fill="#646464"
              />
              <path
                id="Path_134"
                data-name="Path 134"
                d="M33.218,277.32a2.991,2.991,0,1,0,2.746,2.981A2.871,2.871,0,0,0,33.218,277.32Z"
                transform="translate(-27.997 -258.785)"
                fill="#646464"
              />
              <path
                id="Path_135"
                data-name="Path 135"
                d="M285.771,192.312l-2.616-1.248H275v8.816h1.583a3.925,3.925,0,0,1,7.412,0h1.549a.612.612,0,0,0,.584-.665v-6.262A.7.7,0,0,0,285.771,192.312Z"
                transform="translate(-252.663 -180.133)"
                fill="#646464"
              />
              <path
                id="Path_136"
                data-name="Path 136"
                d="M280.466,122.15a1.85,1.85,0,0,0-1.684-1.088H275v4.76h7.222Z"
                transform="translate(-252.663 -116.301)"
                fill="#646464"
              />
              <path
                id="Path_137"
                data-name="Path 137"
                d="M19.172,67.064H1.856A1.962,1.962,0,0,0,0,69.131V80.023H21.038V69.131s0-.006,0-.01A1.969,1.969,0,0,0,19.172,67.064Z"
                transform="translate(0 -67.064)"
                fill="#646464"
              />
              <path
                id="Path_138"
                data-name="Path 138"
                d="M309.051,277.32A2.991,2.991,0,1,0,311.8,280.3h0A2.871,2.871,0,0,0,309.051,277.32Z"
                transform="translate(-281.425 -258.785)"
                fill="#646464"
              />
            </g>
          </g>
        </svg>
      </template>
      <template v-slot:header-title>
        <p class="text-muted font-size-22 m-0 p-0">
          {{ $t("userProfile.transportationDetails") }}
        </p>
      </template>
      <template v-slot:btn-name>
        <router-link
          :to="{ name: 'registerTransportation' }"
          class="d-flex align-items-center"
        >
          <i class="las la-plus-square text-white font-size-22"></i>
          <span class="text-white font-size-16">{{
            $t("userProfile.transportationNow")
          }}</span>
        </router-link>
      </template>
    </user-pages-title>
    <div v-if="loadingData" class="d-flex justify-content-center">
      <spinner-loading text="Loading" />
    </div>
    <div v-else>
      <b-row class="insuraceForm mb-4">
        <b-col lg="4">
          <div class="d-flex align-items-center">
            <label class="m-0"
              ><span class="ml-2 font-size-18 text-muted">{{
                $t("userProfile.status")
              }}</span></label
            >
            <main-select
              :options="status"
              label="label"
              :reduce="(item) => item.value"
              class="flex-grow-1 mb-0 w-100"
              :placeholder="$t('userProfile.status')"
              dir="rtl"
              v-model="tripStatus"
              @change="getUserOrder"
            />
          </div>
        </b-col>
      </b-row>
      <iq-card
        class="py-3 px-3 position-relative"
        v-for="(trip, key) in allOrderTrips"
        :key="key"
        v-b-toggle="`more-content-${trip.id}`"
        @click.native="toogleCards(trip.id)"
      >
        <template>
          <b-row v-if="trip.id !== selectedId" class="align-items-center pl-5">
            <b-col lg="2">
              <div class="d-flex flex-column">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("transportation.tripId") }}
                </p>
                <p class="m-0 p-0 font-size-18 text-muted">{{ trip.id }}</p>
              </div>
            </b-col>
            <b-col lg="5">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap_1">
                  <span class="font-size-16 text-secondary">{{
                    $t("transportation.from")
                  }}</span>
                  <p class="m-0 p-0 font-size-18 text-muted">
                    {{
                      trip.startAddress.length > 30
                        ? trip.startAddress.substring(0, 30) + "..."
                        : trip.startAddress
                    }}
                  </p>
                </div>
                <div class="d-flex align-items-center gap_1">
                  <span class="font-size-16 text-secondary">{{
                    $t("transportation.to")
                  }}</span>
                  <p class="m-0 p-0 font-size-18 text-muted">
                    {{
                      trip.endAddress.length > 30
                        ? trip.endAddress.substring(0, 30)
                        : trip.endAddress
                    }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="d-flex flex-column">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("transportation.totalValueTransport") }}
                </p>
                <p class="m-0 p-0 font-size-20 text-warning">
                  {{ trip.price }}
                  <span>ر.س</span>
                </p>
              </div>
            </b-col>
            <b-col lg="1">
              <div class="arrow-collapse-open">
                <i class="las la-angle-down text-secondary"></i>
              </div>
            </b-col>
            <div :class="`budge-label status--${trip.status} `">
              <p class="font-size-12">{{ trip.status }}</p>
            </div>
          </b-row>
          <b-row v-else class="align-items-center mb-5">
            <b-col lg="3">
              <div class="d-flex flex-column mr-3">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("transportation.OrderNumber") }}
                </p>
                <p class="m-0 p-0 font-size-18 text-muted">{{ trip.id }}</p>
              </div>
            </b-col>
            <b-col lg="7">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center gap_1">
                  <span class="font-size-16 text-secondary">{{
                    $t("transportation.from")
                  }}</span>
                  <p class="m-0 p-0 font-size-18 text-muted">
                    {{ trip.startAddress }}
                  </p>
                </div>
                <div class="d-flex align-items-center gap_1">
                  <span class="font-size-16 text-secondary">{{
                    $t("transportation.to")
                  }}</span>
                  <p class="m-0 p-0 font-size-18 text-muted">
                    {{ trip.endAddress }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col lg="2">
              <div :class="`status--${trip.status} budge-label-inside`">
                <p class="p-0 m-0">{{ trip.status }}</p>
              </div>
            </b-col>
          </b-row>

          <b-collapse
            :id="`more-content-${trip.id}`"
            class="p-0"
            accordion="my-accordion"
          >
            <div>
              <b-row v-if="trip.id === selectedId">
                <b-col lg="12">
                  <div class="fees-company-item">
                    <div
                      class="d-flex justify-content-start align-items-start flex-md-row flex-column mb-5"
                    >
                      <div class="d-flex gap_3 w-50 border-left">
                        <div>
                          <div class="mb-4">
                            <h4 class="fees-company-item-title text-black mb-4">
                              {{ $t("transportation.customerData") }}
                            </h4>
                            <div>
                              <div class="my-4">
                                <h5
                                  class="fees-company-item-title text-secondary"
                                >
                                  {{ $t("transportation.applicant") }}
                                </h5>
                                <h5
                                  class="fees-company-item-response font-size-16 text-muted"
                                >
                                  {{ userNama }}
                                </h5>
                              </div>
                              <div class="my-4">
                                <h5
                                  class="fees-company-item-title text-secondary"
                                >
                                  {{ $t("transportation.distance") }}
                                </h5>
                                <h5
                                  class="fees-company-item-response font-size-16 text-muted"
                                >
                                  {{ trip.distance }}
                                </h5>
                              </div>
                              <div class="my-4">
                                <h5
                                  class="fees-company-item-title text-secondary"
                                >
                                  {{ $t("transportation.time") }}
                                </h5>
                                <h5
                                  class="fees-company-item-response font-size-16 text-muted"
                                >
                                  {{ trip.dateTime }}
                                </h5>
                              </div>
                              <div class="my-4">
                                <h5
                                  class="fees-company-item-title text-secondary"
                                >
                                  {{ $t("transportation.camelNumber") }}
                                </h5>
                                <h5
                                  class="fees-company-item-response font-size-16 text-muted"
                                >
                                  {{ trip.noOfCamels }}
                                </h5>
                              </div>
                              <b-button
                                class="text-warning bg-transparent border-0 cursor-pointer text-decoration-underline font-weight-bold font-size-16"
                                @click.prevent.self="downloadWasl(trip.id)"
                              >
                                {{ $t("marketplace.printReceipt") }}
                              </b-button>
                              <b-button
                                v-if="trip.status === 'upcoming'"
                                class="text-primary bg-transparent border-0 cursor-pointer text-decoration-underline font-weight-bold font-size-16"
                                @click.prevent.self="
                                  openComment(trip.driver.id)
                                "
                              >
                                اضافة تعليق
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pr-3 w-50">
                        <div class="d-flex justify-content-start gap_3">
                          <div class="text-center">
                            <label class="text-gray">{{
                              $t("transportation.DriverPhoto")
                            }}</label>
                            <div
                              class="img-box"
                              :style="{
                                'background-image':
                                  'url( https://nyc3.digitaloceanspaces.com/dafa-projects/ibbil/transportation/vehicles/1729156767467-794210625_Screenshot-2024-10-17-121919.png)',
                              }"
                            ></div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <label class="text-gray">{{
                              $t("transportation.vehicleType")
                            }}</label>
                            <div
                              class="img-box"
                              :style="{
                                'background-image':
                                  'url(' + trip.vehicle_image + ')',
                              }"
                            ></div>
                            <p class="m-0 p-0 text-start">
                              {{ trip.vehicle_name }}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div class="my-4">
                            <h5 class="fees-company-item-title text-secondary">
                              {{ $t("transportation.driverName") }}
                            </h5>
                            <h5
                              class="fees-company-item-response font-size-16 text-muted"
                            >
                              {{ trip.driver.name }}
                            </h5>
                          </div>
                          <div class="mb-4">
                            <h5 class="fees-company-item-title text-secondary">
                              {{ $t("transportation.driverRating") }}
                            </h5>
                            <h5
                              class="fees-company-item-response font-size-16 text-muted"
                            >
                              جيد جدا
                            </h5>
                          </div>
                          <div class="mb-4">
                            <h5 class="fees-company-item-title text-secondary">
                              {{ $t("transportation.numberOfTrips") }}
                            </h5>
                            <h5
                              class="fees-company-item-response font-size-16 text-muted"
                            >
                              1385 رحلة
                            </h5>
                          </div>
                          <hr />
                          <div class="mb-4">
                            <h5
                              class="fees-company-item-response text-secondary font-size-16"
                            >
                              {{ $t("insurance.camelTotalValue") }}
                            </h5>
                            <h3 class="font-weight-bold text-warning">
                              {{ trip.price }}
                              <span>{{ $t("transportation.rs") }}</span>
                            </h3>
                          </div>
                          <!--                        <div class="d-flex justify-content-start gap_2">-->
                          <!--                          <b-button variant="outline-warning" class="px-4 py-2 iq-border-radius-5 text-center">-->
                          <!--                            الإيصال-->
                          <!--                          </b-button>-->
                          <!--                        </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
          <span class="close-collapse" v-if="trip.id === selectedId">
            <i class="las la-angle-up"></i>
          </span>
        </template>
      </iq-card>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-if="pagination.totalItems > pagination.itemsPerPage"
          v-model="pagination.currentPage"
          :total-rows="pagination.totalItems"
          :per-page="pagination.itemsPerPage"
          first-number
          last-number
          class="mb-3 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          @input="getUserOrder()"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import userPagesTitle from "@/modules/userProfile/components/userPagesTitle";
import userProfileServices from "@/modules/userProfile/services/userProfile";
import { VUE_APP_TRANSPORTATION_LINK } from "@/config/constant";
import { core } from "@/config/pluginInit";
export default {
  name: "transportation-profile",
  components: { userPagesTitle },
  data() {
    return {
      stars: [1, 2, 3, 4, 5],
      selectStar: "",
      comment: "",
      count: {},
      visible: false,
      loadingData: false,
      status: [],
      tripStatus: "",
      driver_id: "",
      search: "",
      selectedId: "",
      allTrips: [{ id: 1 }, { id: 2 }],
      allOrderTrips: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      userNama: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.name
        : "",
      userId: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.id
        : "",
    };
  },
  methods: {
    openComment(id) {
      this.driver_id = id;
      this.$bvModal.show("add-comment");
    },
    sendComment() {
      userProfileServices
        .addComment({
          rate: this.selectStar + 1,
          content: this.comment,
          driver_id: this.driver_id,
        })
        .then((res) => {
          this.$bvModal.hide("add-comment");
          core.showSnackbar("success", "تم اضافة التعليق");
        });
    },
    downloadWasl(id) {
      window.open(
        `${VUE_APP_TRANSPORTATION_LINK}transportaion/wasl/trip/${id}`,
        "_blank"
      );
    },
    getUserOrder() {
      this.loadingData = false;
      userProfileServices
        .getUserTripsOrder(this.tripStatus, this.pagination.currentPage)
        .then((res) => {
          this.allOrderTrips = res.data.data;
          this.pagination = res.data.meta;
          this.loadingData = false;
        });
    },
    getTripStatus() {
      userProfileServices.getTripsStatus().then((res) => {
        this.status = [{ label: "الكل", value: "" }, ...res.data];
      });
    },
    getTripCount() {
      userProfileServices.getTripsCount().then((res) => {
        this.count = res.data.data;
      });
    },
    toogleCards(id) {
      if (this.selectedId === id) {
        this.selectedId = "";
      } else {
        this.selectedId = id;
      }
    },
  },
  created() {
    this.getTripStatus();
    this.getTripCount();
    this.getUserOrder();
  },
};
</script>

<style>
.selected {
  color: var(--iq-warning);
}
.font-size-25 {
  font-size: 25px !important;
}
.prod-img img {
  background-size: cover;
  width: 100%;
  max-height: 100%;
}
.budge-label {
  white-space: nowrap;
  position: absolute;
  width: 50px !important;
  /*background-color: #EC5D61;*/
  /*color: #fff;*/
  left: 0;
  height: 100% !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.budge-label p {
  transform: rotate(90deg);
  transform-origin: center;
  margin: 0 !important;
}
.close-collapse {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 20px);
}
.budge-label-inside {
  text-align: center;
  display: inline-block;
  padding: 0 20px;
  border-radius: 0 !important;
}
.arrow-collapse-open {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.53);
  border-radius: 5px;
}
.img-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-color: #d2cece;
  background-repeat: no-repeat;
}
</style>
